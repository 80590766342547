import React from "react";

//Layout
import Main from "../components/Layout/Main";
import FlexContainer from "../components/Layout/FlexContainer";
import FlexItem from "../components/Layout/FlexItem";

//Components
import SEO from "../components/seo"

//Lists
import TodayTomorrowList from "../components/CategoryViews/TodayTomorrowList";
import AllEventsList from "../components/CategoryViews/AllEventsList";
import AllGenresList from "../components/CategoryViews/AllGenresList";
import AllVenuesList from "../components/CategoryViews/AllVenuesList";

const IndexPage = () => {
  let pageContext = { data: {} }
  pageContext.data.desc_event = "Details event information and schedule for all Zrce festivals, clubs and genres"
  pageContext.data.name_event = "Zrce festival and event app by zrce.eu"

  return (
    <React.Fragment>
      <section>
        <SEO context={pageContext} />
        <Main>
          <FlexContainer>
            <FlexItem>
              <h1><span style={{fontSize: 60, color: '#52c2f0'}}>YOUR</span><br /><span style={{fontSize: 80, color: '#fff'}}>ZRCE </span><span style={{fontSize: 60, color: '#52c2f0'}}>EVENT<br /> APP</span></h1>   
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <TodayTomorrowList />   
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <AllVenuesList />        
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <AllEventsList />        
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <AllGenresList />        
            </FlexItem>
          </FlexContainer>
        </Main>
      </section>
    </React.Fragment>
  );
};

export default IndexPage;
